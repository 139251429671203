import axios from 'axios'

const fileDownload = require('js-file-download')

export default async (url, name) => {
  await axios({
    method: 'get',
    url,
    responseType: 'blob',
    headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
  })
    .then(res => {
      fileDownload(res.data, name)
    })
    .catch(e => {
      throw e
    })
}
